// @flow

import React from "react";
import {
    DashboardLayout,
    Requests,
    Layout,
    withPrivateRoute,
} from "@containers";

/**
 * Home Page
 */
const TeamRequests = (props: *) => (
    <Layout {...props}>
        <DashboardLayout assignmentsType="team" title="Alle aanvragen">
            <Requests location={props.location} assignmentsType="team" />
        </DashboardLayout>
    </Layout>
);

export default withPrivateRoute(TeamRequests);
